import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadUsers, loadUser, updateSubscriptionDate } from '../../store/user/thunk'; 
import { allUsersSelector, userSelector } from '../../store/user/selectors'; 
import {
  TableContainer,
  Table,
  TableHead,
  TableRow as TableRowMUI,
  TableCell,
  TableBody,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import { UserManageRows } from 'components/UserManageRows/UserManageRows';

export const UserManage = () => {
  // const dispatch = useAppDispatch();
  
  const { users, loading, error } = useAppSelector((store) => store.user);

  const data = users ? users.data : [];


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRowMUI>
              <TableCell align="center">nickname</TableCell>
              <TableCell align="center">email</TableCell>
              <TableCell align="center">vk_id</TableCell>
              <TableCell align="center">Подписка истекает</TableCell>
              <TableCell align="center">Выставление новой даты подписки</TableCell>
              <TableCell align="center">Подтверждение</TableCell>
            </TableRowMUI>
          </TableHead>

          {data.map((row, index) => ( UserManageRows(row, index)))}

        </Table>
      </TableContainer>
  );
};