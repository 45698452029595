import { Link } from '@mui/material';
import { DONATE_URL } from 'api';
import { useAppDispatch, useAppSelector } from 'hooks';
import { userLogout, userManage } from 'store/user';
import { loadUsers } from '../../store/user/thunk'; 

import './Header.scss';

export const Header = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((store) => store.user);
  const subscriptionDateTill = userInfo?.subscriptionDateTill;

  console.log('userInfo', userInfo)
  console.log('subscriptionDateTill', subscriptionDateTill)
  const handleLogout = () => {
    dispatch(userLogout());
  };

  const handleUserManage = () => {
    dispatch(userManage(true));
    dispatch(loadUsers());
  };

  return (
    <div className="header">
      <a target="_blank" rel="noopener noreferrer" href={DONATE_URL}>
        Отправить благодарность автору
      </a>

      {userInfo?.roleId === '1' ? <Link onClick={handleUserManage}>Управление пользователями</Link> : ''}
      { subscriptionDateTill ? <div>подписка действительна до {subscriptionDateTill}</div> : <div></div>}
      <div className="user-info">
        {userInfo?.nickname}
        <Link onClick={handleLogout}>Выход</Link>
      </div>
    </div>
  );
};
