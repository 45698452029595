export const BASE_URL = ''; // prod
// export const BASE_URL = 'http://localhost:8080'; // dev
export const BASE_API_URL = BASE_URL + '/api';

export const DONATE_URL =
  'https://www.tinkoff.ru/collectmoney/crowd/kuvshinova.elena43/9QITo17315/?short_link=9tlRx227uwa&httpMethod=GET';
  export const FOUNDER_TG_URL =
  'https://t.me/Aerolena_Elena';

export const TG_URL = 'https://t.me/schelk_sostoyanie';
