import { SyntheticEvent, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IncomeTable, PivotTable } from 'components';

import './Tabs.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { userManage, userSelector } from 'store/user';
import { UserManage } from 'components/UserManage/UserManage';

export const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState('income-now');
  const { isUserManage: isUserManageOpened, userInfo } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const handleChangeTab = (_: SyntheticEvent, newValue: string) => {
    dispatch(userManage(false));
    setSelectedTab(newValue);
  };

  const tabs = [
    {
      label: 'Доход сейчас',
      value: 'income-now',
      component: <IncomeTable tableName="incomeNow" />,
    },
    {
      label: 'Планируемый доход',
      value: 'planned-income',
      component: <IncomeTable tableName="plannedIncome" />,
    },
    {
      label: 'Сводная',
      value: 'pivot-table',
      component: <PivotTable />,
    },
  ];

  return (
    <div className="main-tabs">
      <TabContext value={selectedTab}>
        <Box
          className="main-tabs__box"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: '#d88c8e',
            color: 'white',
          }}
        >
          <TabList onChange={handleChangeTab} indicatorColor="primary" textColor="inherit">
            {tabs.map(({ label, value }) => (
              <Tab className="main-tabs__tab" key={value} label={label} value={value} />
            ))}
          </TabList>
        </Box>

        {tabs.map(({ value, component }) => (
          <TabPanel className="main-tabs__panel" key={value} value={value}>
            {isUserManageOpened ? <UserManage/> : component}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
