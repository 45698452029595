import { Button } from '@mui/material';
import { useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import './WelcomePage.scss';
import { DONATE_URL, TG_URL, FOUNDER_TG_URL } from 'api';

export const WelcomePage = () => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((store) => store.user);
  const { isAuthenticated, isActive } = userInfo || {};

  const handleNavigate = () => {
    isAuthenticated && isActive ? navigate('/main') : navigate('/login');
  };

  return (
    <div className="welcome-page">
      <div className="welcome-page__main">
        Привет! <br />
        Ты попал на страницу с инструментом, который помогает считать доход. <br />
        Есть утверждение - там где фокус, то и увеличивается. Поэтому этот инструмент <br />
        используется только для подсчёта дохода.
      </div>

      <div className="welcome-page__description">
        Как этим инструментом пользоваться, зависит только от тебя. <br />
        Предлагаю свой способ, который помогает расширить видение <br />
        дохода через состояние. Смотри <a href="https://www.youtube.com/watch?v=xTK04I5UcFE" target='_blank'>вводное видео на YouTube</a> <br />
        и создавай своё состояние из дохода.<br /><br />
        {/* Пробный период инструмента бесплатный - 21 день со дня регистрации,<br />
        далее, как чашка хорошего кофе - 299 руб. в месяц.<br />
        Оплатить можно{' '}
        <a target="_blank" href={DONATE_URL} rel="noreferrer">
        по ссылке{' '}
        </a>
         и обязательно свой ник сообщи в{' '} 
        <a target="_blank" href={FOUNDER_TG_URL} rel="noreferrer">
        ТГ @Aerolena_elena,
        </a>
        <br />
        там же можно узнать о другом способе оплаты
      </div> */}
      Пробный период инструмента бесплатный - 21 день со дня регистрации,<br />
      далее, как чашка хорошего кофе - 299 руб. в месяц.<br />
      По вопросам оплаты{' '}
        <a target="_blank" href={FOUNDER_TG_URL} rel="noreferrer">
        ТГ @Aerolena_elena.
        </a>
      </div>

      <Button
        className="welcome-page__btn"
        type="submit"
        variant="contained"
        onClick={handleNavigate}
      >
        Перейти в доход
      </Button>
    </div>
  );
};
