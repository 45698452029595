import { useState } from 'react';
import { useAppDispatch } from 'hooks';
import { updateSubscriptionDate } from '../../store/user/thunk'; 
import {
  TableRow as TableRowMUI,
  TableCell,
  TableBody,
  TextField,
  Button,
} from '@mui/material';

export const UserManageRows = (user: any, index: number) => {
  const dispatch = useAppDispatch();
  const { nickname, email, UID, subscriptionDateTill, vk_user_id } = user;

  const [oldSubDate, setOldSubDate] = useState(subscriptionDateTill);
  const [newSubDate, setNewSubDate] = useState('');

  const handleDateChange = (event: any) => {
    setNewSubDate(event.target.value);
  };

  const handleSubmit = () => {
    console.log(`newSubDate1`, newSubDate);
    if (newSubDate) {
        console.log(`newSubDate2`, newSubDate)
      dispatch(updateSubscriptionDate({ UID, subscriptionDateTill: newSubDate }));
      let splittedSubDate = newSubDate.split('-');
      const formatedRussianDate = `${splittedSubDate[2]}.${splittedSubDate[1]}.${splittedSubDate[0]}`;
      setOldSubDate(formatedRussianDate);
    } else {
        console.log(`newSubDate3`, newSubDate)
      alert('Выберите дату');
    }
  };

  return (
    <TableBody>
      <TableRowMUI key={index}>
        <TableCell align="center" className="income-table__total-income">
          {nickname}
        </TableCell>
        <TableCell align="center" className="income-table__total-income">
          {email}
        </TableCell>
        <TableCell align="center" className="income-table__total-income">
          {vk_user_id}
        </TableCell>
        <TableCell align="center" className="income-table__total-income">
          {oldSubDate}
        </TableCell>
        <TableCell align="center" className="income-table__total-income">
          <TextField
            type="date"
            onChange={(event) => handleDateChange(event)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
        <TableCell align="center" className="income-table__total-income">
          <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
            Выставить дату
          </Button>
        </TableCell>
      </TableRowMUI>
    </TableBody>
  );
};