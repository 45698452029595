import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const userSelector = createSelector(
  (state: RootState) => state.user,
  (user) => user,
);

export const allUsersSelector = createSelector(
  (state: RootState) => state.user,
  (user) => user.users,
);



